import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl,
        carreras: urls.carrerasUrl
    },
    googleAnalyticsPropertyId: {},
    domain: {
        profesores: "docentes.fundacoes.ogr.cl",
        alumnos: "www.aprendolivrefundacoes.com.br",
        admin: "admin.fundacoes.ogr.cl"
    },
    facebookAnalyticsPropertyId: {},
    facebook: {
        appId: null,
        apiVersion: null
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: null,
    domain: {
        profesores: "docentes.fundacoes.ogr.cl",
        alumnos: "www.fundacoes.ogr.cl",
        admin: "admin.fundacoes.ogr.cl"
    }
}
