import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CarrerasConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }

    registro = {
        enableCarreras: false,
        universidades: [],
        amount: 3,
        enableNivel: true,
        enableColegio: true,
        enableTelefono: true,
        nombresObligatorio: true,
        carrerasObligatorio: false,
        telefonoObligatorio: true,
        enableFechaNacimiento: true,
        apellidoMaternoObligatorio: false,
        apellidoPaternoObligatorio: true,
        fechaNacimientoObligatorio: true
    }
    carreras = {
        ponderables: [
            {
                orden: 1,
                ponderable: "Matemática",
                asignaturaAsociada: "Matemática",
                tipo: "calificable"
            },
            {
                orden: 2,
                ponderable: "Lenguaje y Comunicación",
                asignaturaAsociada: "Lenguaje",
                tipo: "calificable"
            },
            {
                orden: 3,
                ponderable: "Historia y Ciencias Sociales",
                asignaturaAsociada: "Historia y Ciencias Sociales",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 4,
                ponderable: "Ciencias",
                asignaturaAsociada: "Ciencias PSU",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 5,
                ponderable: "NEM",
                alias: "Puntaje NEM",
                clase: "nem",
                tipo: "ingresable",
                field: "nem",
                minValue: 150,
                maxValue: 850
            },
            {
                orden: 6,
                ponderable: "Ranking",
                clase: "ranking",
                tipo: "ingresable",
                field: "ranking",
                minValue: 150,
                maxValue: 850
            }
        ]
    } as CarrerasConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {
                logo: "logoSmall"
            },
            landing: {
                logo: "logoLargeLight",
                subtitle: "Prepare-se para o ENEM 2022",
                logoFooter: "logoSmallLight",
                subtitleHasShadow: true,
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/d31d2a1c039575e7fbe3c75af359c873Brasil (3900 × 2500 px).png"
            },
            sharedLayout: {
                logo: "logoLargeLight",
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/8ac87cc9426efdeffc4c598920c6ff60Brasil (3900 × 2500 px).png"
            },
            ayuda: {
                showYoutubeButton: false
            }
        },
        menuConfig: {
            tipo: "horizontal"
        },
        showcasesByApp: {
            alumnos: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 0,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Noticias",
                    columna: 0,
                    identificador: "noticias-alumnos-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Notificaciones",
                    columna: 1,
                    identificador: "notificaciones-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Ensayos Mensuales",
                    columna: 1,
                    identificador: "ensayos-mensuales"
                },
                {
                    orden: 0,
                    nombre: "Banners",
                    columna: 2,
                    identificador: "arte-home-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Entrenador",
                    columna: 2,
                    identificador: "entrenador-showcasebox"
                }
            ],
            profesores: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 1,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Noticias profesor",
                    columna: 1,
                    identificador: "noticias-showcasebox"
                },
                {
                    orden: 2,
                    nombre: "Cursos profesor",
                    columna: 1,
                    identificador: "grupo-usuarios-profesor-showcase"
                },
                {
                    orden: 0,
                    nombre: "Menu box",
                    columna: 2,
                    identificador: "base-menu-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Lista establecimientos",
                    columna: 2,
                    identificador: "lista-establecimientos-profesor"
                }
            ]
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "libro2-o",
                    name: "base_topmenu.alumno.blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "base_topmenu.alumno.ejercitar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "base_topmenu.alumno.practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "base_topmenu.alumno.resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.alumno.planes_adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.alumno.planes_de_clase",
                    route: "/plan_clases"
                },
                {
                    icon: "dialogos",
                    name: "base_topmenu.alumno.centro_ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "dialogos",
                    name: "base_topmenu.alumno.biblioteca",
                    route: "/biblioteca"
                }
            ],
            profesores: [
                {
                    icon: "libro2-o",
                    name: "base_topmenu.profe.blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "base_topmenu.profe.evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "base_topmenu.profe.corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "base_topmenu.profe.resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.profe.planes_de_clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "base_topmenu.profe.mis_noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "base_topmenu.profe.planes_clase_predefinido",
                    route: "/plan_clases_predefinidos"
                },
                {
                    icon: "dialogos",
                    name: "base_topmenu.profe.centro_ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "resultados",
                    name: "base_topmenu.profe.resumen_uso",
                    route: "/estadisticas/resumen_uso"
                }
            ]
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias_naturales",
            abreviacion: "Ciências da Natureza",
            asignaturaPlataforma: "Ciências da Natureza",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            asignatura: "Lenguaje y Códigos",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Linguagens e Códigos",
            asignaturaPlataforma: "Linguagens e Códigos",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        },
        {
            asignatura: "Ciencias Humanas",
            icono: "historia",
            clase: "historia",
            abreviacion: "Ciências Humanas",
            asignaturaPlataforma: "Ciências Humanas",
            evaluacionTipo: ["Enem", "diagnostico Enem"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "fundacoes",
        id: null,
        pais: "brasil",
        locale: "pt",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["CPF"],
        identificadorEstablecimiento: ["inep_mec "],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        hojaRespuestaEjemploLink:
            "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/latex/hoja_aprendolivre.pdf",
        info: {
            social: {
                facebook: "https://www.facebook.com/aprendolibre.co/",
                twitter: "https://twitter.com/aprendolibre",
                youtube: "https://www.youtube.com/channel/UCTzMx0G4b7hLXDycu3DISjw"
            },
            soporte: {
                mail: "monitores@aprendolibre.co"
            },
            contacto: {
                telefono: "00000000",
                mail: ""
            },
            companyName: "Aprendolivre",
            companySite: "www.aprendolivre.com.br"
        },
        evaluacionTipoAdaptiveDefault: "diagnostico Enem",
        evaluacionDefault: "Enem",
        evaluacionResponder: "secuencial",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["Enem", "diagnostico Enem"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        showAyuda: true,
        showMisCarreras: true,
        enableAchievements: true,
        identificadorUsuarioAlias: {
            CPF: "CPF"
        },
        nombreSesiones: "Aulas",
        nombreSesion: "Aula",
        planPersonalesDisableNivel: true,
        disableButtonDuda: true,
        planClasesRouteDocentes: "/plan_clases",
        hideReforzamientos: true,
        disableZopim: true
    } as PlataformaType

    evaluaciones = {
        Enem: {
            alias: "Prova",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cursos: [
                {
                    label: "Diplomado",
                    clasificacion: "Egresado"
                }
            ],
            baseUrl: "enem",
            cursoTipo: "curso",
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: [],
                methods: "clasificaciones",
                estadisticas: ["Disciplina Brasil", "macro áreas brasil", "Macrotema Brasil"],
                referencesTo: {
                    "Macrotema Brasil": "macro áreas brasil",
                    "macro áreas brasil": "Disciplina Brasil"
                },
                configuration: [
                    {
                        name: "Disciplina",
                        htmlId: "Disciplina_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Disciplina Brasil"
                    },
                    {
                        name: "Macroarea",
                        htmlId: "macro_áreas brasil",
                        hasHijos: true,
                        clasificacionTipo: "macro áreas brasil"
                    },
                    {
                        name: "Macrotema",
                        htmlId: "Macrotema_Brasil",
                        clasificacionTipo: "Macrotema Brasil"
                    }
                ],
                cambiarPregunta: [],
                desempenoEvaluacion: "Disciplina Brasil,macro áreas brasil,Macrotema Brasil",
                estadisticasEvolutivas: ["Macrotema Brasil"],
                clasificacionTiposTablaEspecificaciones: ["Macrotema Brasil"]
            },
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 10000,
                min: 0,
                tickInterval: 100
            },
            clasificacionMaterial: {
                label: "Macroaera",
                clasificacion: "macro áreas brasil"
            },
            streamingChannelAlias: "Aulas ao vivo",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Macrotema Brasil",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["Diplomado"],
            planPersonalClasificacionMateriales: [
                {
                    label: "Macrotema",
                    clasificacion: "Macrotema Brasil"
                }
            ]
        },
        "diagnostico Enem": {
            alias: "Prova",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cursos: [
                {
                    label: "Diplomado",
                    clasificacion: "Egresado"
                }
            ],
            baseUrl: "/enem",
            cursoTipo: "curso",
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["Disciplina Brasil,macro áreas brasil,Macrotema Brasil"],
                methods: "clasificaciones",
                estadisticas: ["Disciplina Brasil", "macro áreas brasil", "Macrotema Brasil"],
                referencesTo: {
                    "Macrotema Brasil": "macro áreas brasil",
                    "macro áreas brasil": "Disciplina Brasil"
                },
                configuration: [
                    {
                        name: "Disciplina",
                        htmlId: "Disciplina_Brasil",
                        hasHijos: true,
                        clasificacionTipo: "Disciplina Brasil"
                    },
                    {
                        name: "Macroarea",
                        htmlId: "macro_áreas brasil",
                        hasHijos: true,
                        clasificacionTipo: "macro áreas brasil"
                    },
                    {
                        name: "Macrotema",
                        htmlId: "Macrotema_Brasil",
                        clasificacionTipo: "Macrotema Brasil"
                    }
                ],
                cambiarPregunta: [],
                desempenoEvaluacion: "Disciplina Brasil,macro áreas brasil,Macrotema Brasil",
                estadisticasEvolutivas: ["Macrotema Brasil"],
                clasificacionTiposTablaEspecificaciones: ["Macrotema Brasil"]
            },
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 10000,
                min: 0,
                tickInterval: 100
            },
            clasificacionMaterial: {
                label: "Macrotema",
                clasificacion: "Macrotema Brasil"
            },
            streamingChannelAlias: "Aulas ao vivo",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Macrotema Brasil",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["Diplomado"],
            planPersonalClasificacionMateriales: [
                {
                    label: "Macrotema",
                    clasificacion: "Macrotema Brasil"
                }
            ]
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5,
        rutFormaByMarkers: {
            10: {
                rutMarkerUp: 6,
                rutMarkerBottom: 10,
                rutCols: 11,
                formaMarkerUp: 9,
                formaMarkerBottom: 6,
                formaCols: 11
            }
        },
        alignByMarkers: {
            10: [1, 5]
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
